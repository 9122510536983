<template>
  <NuxtLink
    :to="blok?.link?.url ? blok.link.url : `/${locale}`"
    class="uppercase tracking-wider font-medium text-10 whitespace-nowrap"
    >{{ blok.text }}</NuxtLink
  >
</template>

<script setup>
defineProps({
  blok: Object,
})

const { locale } = useI18n()
</script>
